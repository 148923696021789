<template>
  <nav class="navbar navbar-expand-sm navbar-dark justify-content-center pt-2">
    <a class="navbar-brand" href="/">
      <img src="./assets/LogoWhite.png" width="40" height="40" alt="Xster创意设计" />
    </a>

    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarNavDropdown"
      aria-controls="navbarNavDropdown"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarNavDropdown">
      <ul class="nav navbar-nav justify-content-center">
        <li class="nav-item">
          <router-link to="/" class="nav-link active" id="nav-home"> Realm </router-link>
        </li>
        <li v-if="false" class="nav-item">
          <router-link to="/about" class="nav-link" id="nav-about"> About </router-link>
        </li>
        <li class="nav-item">
          <router-link to="/work" class="nav-link" id="nav-work"> Discover </router-link>
        </li>
        <li class="nav-item">
          <router-link to="/colors" class="nav-link" id="nav-work"> Colors </router-link>
        </li>
        <li class="nav-item">
          <router-link to="/contact" class="nav-link" id="nav-contact">
            Contact
          </router-link>
        </li>
      </ul>
    </div>
  </nav>

  <div v-if="false" id="top-left-border" />
  <div id="bottom-right-border" />

  <main>
    <router-view></router-view>
  </main>

  <my-footer />
</template>

<script>
// import HomePage from './components/home/HomePage.vue'

export default {
  name: "App",
  components: {
    // HomePage
  },
};
</script>

<style>
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  color: #f7f7f7;
  background-color: rgb(77, 77, 80);
  background-image: url("./assets/home-bg.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  /* font-family: 'Cairo', sans-serif; */
  font-family: "Gochi Hand", cursive;
}

#sketch-holder {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1;
  /* bottom: -8px; */
}

nav {
  background-color: rgba(0, 68, 170, 0.3);
  z-index: 1;
  width: 100%;
}

.nav-item {
  font-size: 1.5em;
}

main {
  position: fixed;
  /* border: 2px solid red; */
  width: 100vw;
  height: 85vh;
  z-index: 3;
}

#top-left-border {
  position: fixed;
  border-top: 10px solid rgba(0, 102, 255, 0.2);
  border-left: 10px solid rgba(0, 102, 255, 0.2);
  width: 100px;
  height: 100px;
  z-index: 5;
  top: 10px;
  left: 10px;
}

#bottom-right-border {
  position: fixed;
  border-bottom: 10px solid rgba(0, 102, 255, 0.2);
  border-right: 10px solid rgba(0, 102, 255, 0.2);
  width: 100px;
  height: 100px;
  z-index: 5;
  right: 10px;
  bottom: 10px;
}
</style>
