<template>
	<div class="container">
		<div class="pt-3 row justify-content-start">
			<div class="col-3">
				<img class="img-fluid" src="../../assets/me.jpg" alt="Xster_Selfie">
			</div>
			<div class="col">
				<h3>Get in touch!</h3>
				<h4>Email: 495949430@qq.com</h4>
			</div>
		</div>
	</div>
</template>

<script>
export default {

}
</script>

<style scoped>
.container {
	position: relative;
	background: rgba(0, 68, 170, 0.3);
	overflow: scroll;
	height: 80vh;
	-ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
}

.container::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
</style>