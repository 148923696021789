<template>
  <div class="container">
    <div class="jumbotron">
      <h1 class="display-3">Xster Realm</h1>
      <p class="lead mt-3">To present the mindset of Xster</p>
      <p class="m-0">Don't lose yourself</p>
    </div>
  </div>
</template>

<script>
// import axios from "axios";
export default {
  async mounted() {
    // try {
    //   const response = await axios.get('/about', {baseURL: "http://127.0.0.1:8081"});
    //   console.log(response);
    // } catch (error) {
    //   console.error(error);
    // }
  },
};
</script>

<style scoped>
.jumbotron {
  left: 0px;
  color: rgb(231, 231, 231);
  background-color: rgba(0, 68, 170, 0.3);
  width: 450px;
  border-radius: 0;
  border-bottom-right-radius: 100px;
  z-index: 5;
}
</style>
