<template>
<div class="container">
	<div class="pt-3 ">
		<h1>Discover</h1>
		<h2>Discover</h2>
		<h3>Discover</h3>
		<h4>Discover</h4>
		<h5>Discover</h5>
	</div>

	<div>
		<h1>What</h1>
		<h2>What</h2>
		<h3>What</h3>
		<h4>What</h4>
		<h5>What</h5>
	</div>

	<div>
		<h1>I</h1>
		<h2>I</h2>
		<h3>I</h3>
		<h4>I</h4>
		<h5>I</h5>
	</div>

	<div>
		<h1>Do</h1>
		<h2>Do</h2>
		<h3>Do</h3>
		<h4>Do</h4>
		<h5>Do</h5>
	</div>

	<div>
		<h1>Past</h1>
		<h2>Past</h2>
		<h3>Past</h3>
		<h4>Past</h4>
		<h5>Past</h5>
	</div>

	<div>
		<h1>Present</h1>
		<h2>Present</h2>
		<h3>Present</h3>
		<h4>Present</h4>
		<h5>Present</h5>
	</div>

	<div>
		<h1>Future</h1>
		<h2>Future</h2>
		<h3>Future</h3>
		<h4>Future</h4>
		<h5>Future</h5>
	</div>

</div>

</template>

<script>
export default {

}
</script>

<style scoped>
.container {
	position: relative;
	background: rgba(0, 68, 170, 0.3);
	overflow: scroll;
	height: 80vh;
	-ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
}

.container::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

</style>