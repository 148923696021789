<template>
	<footer>
			<div>
					<p>Xster by XW © 2021 All Rights Reserved </p>
					<p>Xster 版权所有</p>

					<p>
							<a class="footer-beian" href="https://beian.miit.gov.cn">
									京ICP备19044821号
							</a>
							<span class="mx-1 footer-beian"> | </span>
							<a class="footer-beian" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802029881">
									<img src="../../assets/beian-logo.png" />
									<span>京公网安备 11010802029881号</span>
							</a>
					</p>
			</div>
	</footer>
</template>

<script>
export default {
}
</script>

<style scoped>
	footer {
		position: fixed;
		bottom: 10px;
		margin: 0;
		padding: 0;
		margin-top: 5px;
    width: 100%;
    color: rgb(231, 231, 231);
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    font-family: 'Cairo', sans-serif;
		font-size: 0.85em;
		z-index: 3;
}

.footer-beian {
    color: rgb(155, 154, 154);
}

.footer-beian:hover {
    color: rgb(253, 187, 100);
}

p {
	margin: 0;
	padding: 0;
}


</style>