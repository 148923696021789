import { createApp } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import App from './App.vue';
import MyFooter from "./components/partials/MyFooter.vue";
import HomePage from "./components/home/HomePage.vue";
import DiscoverPage from "./components/discover/DiscoverPage.vue";
import ColorRefPage from "./components/discover/ColorRefPage.vue";
import ContactPage from "./components/contact/ContactPage.vue";


const app = createApp(App);


const router = createRouter({
	history: createWebHistory(),
	routes: [
		{ path: "/", component: HomePage },
		{ path: "/work", component: DiscoverPage },
		{ path: "/contact", component: ContactPage },
		{ path: "/colors", component: ColorRefPage }
	]
});

app.use(ElementPlus)
app.component("my-footer", MyFooter);

app.use(router);

app.mount('#app');
